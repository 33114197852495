import "shared/global";

import NotificationBarModel from "aa/vue/models/NotificationBarModel";
import NotificationBanner from "components/NotificationBanner";
import DropdownFilter from "components/dropdownFilters/DropdownFilter";

let notification_banner = new NotificationBanner();
let category_filter = new DropdownFilter();
let program_filter = new DropdownFilter();
let category_selection = '';
let program_selection = '';
let feedback_form = null;
let full_name_el = null;
let email_el = null;
let feedback_el = null;
let error_time = 10000;

let $first_dropdown = $('.first-dropdown .js-dropdown');
let $second_dropdown = $('.second-dropdown .js-dropdown');

document.addEventListener('DOMContentLoaded', () => {
    initDropdowns();
    initForm();

    if (show_notification === true) {
        showNotification();
    }

    function showNotification() {
        setTimeout(function () {
            let nbm = new NotificationBarModel({
                success: true, // (optional, default: true)
                message: "Thanks for your feedback! We'll make sure it gets to the right department."   // (required)
            });
            notification_banner.openNotification(nbm);
        }, 1000)
    }
});

function initDropdowns() {
    category_filter.setMount($first_dropdown).build();
    program_filter.setMount($second_dropdown).build();

    $(category_filter).on('filterSelected', (e, index, value) => {
        e.preventDefault();
        selectProgram(value);
        category_selection = value;
        closeDropdowns();
    });

    $(program_filter).on('filterSelected', (e, index, value) => {
        e.preventDefault();
        let program_options = $second_dropdown.find('li').find('.content').find('li');
        let _selected = program_options[index];
        program_selection = _selected.children[0].innerHTML.replace('&nbsp;', ' ');
        checkSubmitBtnState();
        closeDropdowns();
    });

    //bugfix: if there are no program options don't show the category option.
    let category_options = document.getElementsByClassName('first-dropdown')[0].getElementsByClassName('content')[0].getElementsByTagName('a');
    for(let i = 0; i < category_options.length; i++) {
        //if there are no matches in program dropdown
        let match = category_options[i].innerHTML.trim().toLowerCase();
        let program_options = document.getElementsByClassName('second-dropdown')[0].getElementsByClassName('content')[0].getElementsByTagName('a');
        let isMatched = false;
        for(let j=0; j < program_options.length; j++ ) {
            if(program_options[j].getAttribute('data-value') === match) {
                isMatched = true;
            }
        }
        if(!isMatched) {
            let li = category_options[i].parentElement;
            li.classList.add('inactive');
        }
    }
}

//fixes bug in dropdowns where dropdowns don't want to close, ever.
function closeDropdowns() {
    let dropdowns = document.getElementsByClassName('content');
    for ( let i = 0; i < dropdowns.length; i++ ) {
        dropdowns[i].classList.add('hidden');
    }
    setTimeout(() => {
        for ( let i = 0; i < dropdowns.length; i++ ) {
            dropdowns[i].classList.remove('hidden');
        }
    }, 1000);
}

function selectProgram(category) {
    $second_dropdown.find('li').find('.content').removeClass('hidden');

    let program_options = $second_dropdown.find('li').find('.content').find('li');
    program_options.addClass('inactive');

    for (let i = 0; i < program_options.length; i++) {

        let anchor_tag = $(program_options[i]).find('a');

        if (anchor_tag.attr('data-value') === category) {
            anchor_tag.parent().removeClass('inactive');
        }
    }
    $('.second-dropdown').removeClass(('inactive'));
}

function clearOptions() {
    $second_dropdown.find('li').find('.content').find('li').addClass('inactive');
}

// Feedback -- editable div
$('#feedback_copy').on('focusin', function () {
    $('#feedback-label').addClass('floated');
});
$('#feedback_copy').on('focusout', function () {
    if ($(this).html() === '') {
        $(this).removeClass('focused');
        $('#feedback-label').removeClass('floated');
    }
});
$('.field-wrapper--floating-label').bind("focusin", function () {
    $(this).addClass('focused').find('label').addClass('floated');
});
$('.field-wrapper--floating-label').bind("focusout", function () {
    if ($(this).find('input').val() === '') {
        $(this).removeClass('focused').find('label').removeClass('floated');
    }
});
//dynamically limit character count in feedback div as user types
let feedback_element = document.getElementById("feedback_copy");
feedback_element.addEventListener("input", function() {
    let $feedback_val = $.trim(feedback_element.value);
    if($feedback_val.length > error_time) {
        showError('feedback_copy', 'Sorry! There is a character limit of 2000.');
    }
}, false);
//prevent styles from being copy pasted into feedback div
feedback_element.addEventListener("paste", function(e) {
    let copy_el = document.getElementById('feedback_copy');
    e.preventDefault();
    this.focus();
    let text = '';

    if (window.clipboardData && window.clipboardData.getData) { // IE
        text = window.clipboardData.getData('Text');
    }
    else if (e.clipboardData || e.originalEvent.clipboardData) {//works for chrome/FFox
        text = (e.originalEvent || e.clipboardData.getData('text/plain'));
    } else {
        showError('feedback_copy', 'Sorry! There are unacceptable characters in your pasted text!');
    }

    if (document.queryCommandSupported('insertText')) {
         if(document.execCommand('insertText', false, text)) {//Chrome
         } else {//Firefox
             //because FFox doesn't like execCommand insertText
             let d = document.createElement('div');
             d.innerHTML = text;
             let new_copy = copy_el.value + d.innerText;
             new_copy = new_copy.replace(/\u2013|\u2014/g, "-")
                 .replace(/[\u2018\u2019]/g, "'")
                 .replace(/[\u201C\u201D]/g, '"');
             copy_el.value = new_copy;
         }
    } else {//IE
        let d = document.createElement('div');
        d.innerHTML = text;
        copy_el.value = copy_el.value + d.innerText;
    }

}, false);


let submitForm = function (e) {
    e.preventDefault();
    let form = $('#feedback-form');
    if(!checkSubmitBtnState()) {
        dropdownError('Please make sure to fill every field!', 'submit_err');
        return false;
    }
    if (validateForm(form)) {
        grecaptcha.execute();
    }
};

function sendFeedback() {
    var captchaResponse = grecaptcha.getResponse();

    if (captchaResponse == "") {
        dropdownError('Please complete the catpcha!');
        return false;
    }
    grecaptcha.reset();
    let form = $('#feedback-form');
    let sender_data_el = document.getElementsByName('sender_data')[0];
    let params = {
        fullName: $.trim(form.find('#fullName').val()),
        email: $.trim(form.find('#email').val()),
        program: program_selection.trim(),
        feedback_copy: document.getElementById('feedback_copy').value.replace(/\n/g, "<br>").trim(),
        sender_data: sender_data_el.value.trim(),
        subject: 'Show Feedback: ' + program_selection.trim(),
        recaptchaResponse: captchaResponse
    };

    $.ajax({
        url: '/showfeedback/send-feedback/', type: 'POST', data: params, dataType: 'json',
        success: function (resp) {
            if (resp.success === true) {
                om.trackAction("trackShowFeedbackSubmit",{});
                window.location.href = '/showfeedback/success/';
            } else {
                console.log('error', resp);
                dropdownError('There was a problem with your submission. Please try again.', 'submit_err');
            }
        },
        error: function (resp) {
            console.log('error', resp);
        }
    });
}

window.sendFeedback = sendFeedback;

function validateForm() {
    let valid = true;
    let feedback_form = $('#feedback-form');

    if (category_selection === '') {
        dropdownError('Please make a selection', 'category');
        valid = false;
    }

    if (program_selection === '') {
        dropdownError('Please make a selection', 'program');
        valid = false;
    }

    let $fullName = $.trim(feedback_form.find('#fullName').val());
    const name_test_re = /^[a-zA-Z'. ]*$/;
    if (($fullName.length < 1) || ($fullName.split(' ').length < 2) || (!name_test_re.test($fullName))) {
        showError('fullName', 'First and last name required');
        valid = false;
    }
    let $email = $.trim(feedback_form.find('#email').val());
    const email_test_re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (($email.length < 1) || (!email_test_re.test($email))) {
        showError('email', 'You must enter a valid email');
        valid = false;
    }
    let $feedback_val = document.getElementById('feedback_copy').value.replace(/\n/g, "&#10;");
    const feedback_test_re = /^[\w,.!?"' ;:<>|\/\\{}[\]=+\-()*&^%$#@~`“”\^×€£¥§…‘₩°•○●□■☆⊙▪︎¤÷《》¡¿]*$/;
    if (($feedback_val === "") || (!feedback_test_re.test($feedback_val))) {
        showError('feedback_copy', 'You must enter some feedback! Please use only alphanumeric characters.');
        valid = false;
    }
    if ( $feedback_val.length > 2000 ) {
        showError('feedback_copy', 'Sorry! There is a character limit of 2000.');
        valid = false;
    }

    return valid;
}

function dropdownError(msg, el_id) {
    let err = $('#' + el_id);
    err.html(msg);
    err.removeClass("hidden");

    setTimeout(() => {
        $('._err').addClass("hidden").empty();
    }, 4000);
}

function showError(el, msg) {
    let sibling = $('#' + el).parent().siblings()[0];
    sibling.innerHTML = msg;
    $(sibling).removeClass("hidden");

    setTimeout(() => {
        $('._err').addClass("hidden").empty();
    }, 4000);
}


$(document).ready(function () {
    let submit_btn = document.getElementById('submit_btn');
    submit_btn.onclick = submitForm;
});


function initForm() {
    resetForm();
    feedback_form = document.getElementById('feedback-form');
    full_name_el = document.getElementById('fullName');
    email_el = document.getElementById('email');
    feedback_el = document.getElementById('feedback_copy');
    initInputs();
}

function resetForm() {
    $('#fullName').val('');
    $('#email').val('');
    $('#feedback_copy').val('');

    $second_dropdown.find('li').find('.content').addClass('hidden');

    let len = $first_dropdown.find('li').find('.content').find('li').length - 1;
    category_filter.setSelectedIndex(len + 1);

    clearOptions();
}

function initInputs() {
    let inputs = document.getElementById('feedback-form').getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener('input', checkSubmitBtnState);
    }
    feedback_el.addEventListener('input', checkSubmitBtnState);
}

function checkSubmitBtnState() {
    if (
        $('#fullName').val() === '' ||
        $('#email').val() === '' ||
        $('#feedback_copy').val() === '') {
        $('#submit_btn').addClass('disabled');

        return false;
    } else {
        $('#submit_btn').removeClass('disabled');

        return true;
    }
}